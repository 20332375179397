import { useEffect, useState } from "react";
import { Cascader } from "antd";

import SearchInBoard from "../SearchInBoard";
import FooterInBoard from "../FooterInBoard";
import { BoardType } from "../../type";

import styles from "../../style/index.module.scss";

const Board = ({
  options,
  value,
  onChange,
  onCloseDropdown,
  boardClearText,
  boardApplyFiltersText,
  searchBoardPlaceholder,
  visibleSearch = true,
  searchValue,
  setSearchValue,
  setSelectValue,
  selectValue,
  onMultipleChange,
  realTimeRefresh,
  cascaderPanelProps,
  total
}: BoardType) => {
  const customOptions = options.map((item) => ({
    ...item,
    label:
      item.sort_order <= 4 ? (
        <span style={{ display: "inline-block", width: "100%", minWidth: "800px !important" }}>
          <span>{item.label}</span>
          <span className={styles.hotLabel}>Hot</span>
        </span>
      ) : (
        item.label
      )
  }));

  return (
    <div style={{ padding: 12 }}>
      {visibleSearch && (
        <SearchInBoard
          options={options}
          setValue={setSelectValue}
          searchBoardPlaceholder={searchBoardPlaceholder}
          searchValue={searchValue}
          onChange={onChange}
          value={value}
          realTimeRefresh={realTimeRefresh}
          setSearchValue={setSearchValue}
        />
      )}
      {visibleSearch && <div style={{ height: 14 }} />}

      <Cascader.Panel
        multiple
        options={customOptions}
        value={selectValue}
        onChange={onMultipleChange}
        showCheckedStrategy='SHOW_CHILD'
        className={styles.overridePanel}
        expandTrigger='hover'
        {...cascaderPanelProps}
      />

      {!realTimeRefresh && (
        <FooterInBoard
          value={selectValue}
          total={total}
          onChange={onChange}
          setValue={setSelectValue}
          setSearchValue={setSearchValue}
          onCloseDropdown={onCloseDropdown}
          boardClearText={boardClearText}
          boardApplyFiltersText={boardApplyFiltersText}
          realTimeRefresh={realTimeRefresh}
        />
      )}
    </div>
  );
};

export default Board;
