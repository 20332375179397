import classNames from "classnames";
import { useState } from "react";

import styles from "./index.module.scss";

export type SwitchCardType = "row" | "column";
type TProps = {
  cardType: SwitchCardType;
  setCardType: (value: SwitchCardType) => void;
};

const Switch = ({ cardType = "column", setCardType }: TProps) => {
  return (
    <div className={styles.controller}>
      <div
        className={classNames({
          [styles.box]: true,
          [styles.active]: cardType === "column"
        })}
        onClick={() => setCardType("column")}
      >
        <LeftSvg />
      </div>
      <div
        className={classNames({
          [styles.box]: true,
          [styles.active]: cardType === "row"
        })}
        onClick={() => setCardType("row")}
      >
        <RightSvg />
      </div>
    </div>
  );
};

const LeftSvg = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='3.66663'
        y='12.8333'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
      <rect
        x='3.66663'
        y='3.94165'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
      <rect
        x='12.8334'
        y='4.125'
        width='5.5'
        height='14.2083'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
    </svg>
  );
};

const RightSvg = () => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='3.6665'
        y='12.8333'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
      <rect
        x='12.8333'
        y='12.8333'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
      <rect
        x='3.6665'
        y='3.94165'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
      <rect
        x='12.8333'
        y='3.94165'
        width='5.5'
        height='5.5'
        rx='1.375'
        stroke='#363636'
        strokeWidth='1.83333'
      />
    </svg>
  );
};

export default Switch;
