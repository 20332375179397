"use client";
import React, { useEffect } from "react";
import Link from "next/link";
import { addEventTag } from "helpers/utilities";
import { hotSearch } from "store/services/eventTrack/record";

interface LinkClientProps {
  children: React.ReactNode;
  href: string;
  className?: string;
  [key: string]: any;
}

const LinkClient = (props: LinkClientProps) => {
  const { children, data, ...rest } = props;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    addEventTag("hp_popular_jobs_click");
    handleClickHotSearch();
  };

  const handleClickHotSearch = () => {
    const params = {
      page: "Homepage Popular Job",
      source: data?.source,
      query: data?.value,
      job_title_id: data?.job_title_id
    };
    hotSearch(params);
  };

  return (
    <Link {...rest} onClick={onClick}>
      {children}
    </Link>
  );
};

export default LinkClient;
